import React from "react";
import clsx from "clsx";

type CardProps = {
  isFirstChild?: boolean;
  title: string;
  description: string;
  src: string;
};

const Card: React.FC<CardProps> = ({
  isFirstChild = false,
  title,
  description,
  src,
}) => {
  const cardClasses = clsx(
    "card bg-dark rounded-3 mb-3",
    isFirstChild ? "mx-lg-4" : "w-100 d-flex flex-column"
  );

  // add player parameters
  // https://developers.google.com/youtube/player_parameters
  src = `${src}?cc_lang_pref=en&cc_load_policy=1&rel=0&hl=en&color=white`;

  return (
    <div className={cardClasses}>
      <div className="row g-0 align-items-center">
        <div
          className={clsx({
            "col-lg-6 px-4 pt-4 pb-2 pb-lg-4": isFirstChild,
            "px-4 pt-4 pb-2": !isFirstChild,
          })}
        >
          <iframe
            width="100%"
            height="266"
            src={src}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
        <div className={clsx(isFirstChild ? "col-lg-6" : "")}>
          <div className="card-body mb-4">
            <h4 className="card-title text-success fw-medium mb-3">{title}</h4>
            <p className="card-text text-white">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const VideoCards: React.FC = () => {
  return (
    <>
      <div className="container mb-5">
        <Card
          isFirstChild={true}
          title="What motivates young people to volunteer?"
          description="Learn what motivates young people to volunteer and what value can they bring to your organisation."
          src="https://www.youtube.com/embed/5Bm2RH4EKjY"
        />
      </div>

      <div className="container my-5">
        <div className="row g-4 d-flex">
          <div className="col-12 col-md-6 d-flex">
            <Card
              title="How do you find young people?"
              description="Learn how to find young people and attract them to your volunteer roles."
              src="https://www.youtube.com/embed/KXT-vlwFfHk"
            />
          </div>
          <div className="col-12 col-md-6 d-flex">
            <Card
              title="How do you retain young volunteers?"
              description="Learn how to retain young in Volunteer positions."
              src="https://www.youtube.com/embed/wY2q3gnWX9g"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoCards;
