export const faq = [
  {
    title: "What is the age range for 'youth'?",
    content: (
      <p>
        Youth age group classifications vary, however in this program ‘youth’
        refers to 15 – 24 year olds. When looking at engaging youth under 18
        years of age in a volunteer role, consider the young person&apos;s
        individual skills and experience, knowledge of rights and
        responsibilities, confidence and also vulnerability. See more
        information at{" "}
        <a
          href="https://www.nfplaw.org.au/volunteers#youth"
          target="_blank"
          rel="noopener noreferrer"
        >
          nfplaw.org.au/volunteers
        </a>
      </p>
    ),
  },
  {
    title: "What can youth volunteers bring to our organisation?",
    content: (
      <>
        <p>
          Young people can bring much in the way of talent, enthusiasm, energy,
          new perspectives and creativity. Adding a youthful voice to your
          organisation, committees, and programs is a powerful move – their
          voice can help you reach an even larger target or client base. If you
          would like to recruit younger clients/customers, youth volunteers can
          help you shape your messaging to appeal to this demographic.
        </p>
        <p>
          They may be open to new ideas, accept change readily, and are able to
          grow into roles where appropriate responsibility and support is given.
          Youth can also be open to inclusion and helping to promote
          intergenerational connections. Youth can add value through their
          diverse technology and social media skills.
        </p>
      </>
    ),
  },
  {
    title:
      "Does an organisation need consent/permission when recruiting youth?",
    content: (
      <>
        <p>
          Securing a parent or guardian&apos;s consent for under 18&apos;s is
          considered good practice, where possible. In designing roles for
          younger volunteers also consider:
        </p>
        <ul>
          <li>
            Appropriate supervision given age and experience, including any
            special needs
          </li>
          <li>
            Adjusting the time of day or duration of volunteering (e.g. hours in
            a week)
          </li>
          <li>Adapting around school/education commitments</li>
          <li>
            Appropriate working conditions noting health and safety of the young
            person.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: "What checks are required for working with youth?",
    content: (
      <>
        <p>
          A Working with Children (WWC) Check is required by a person if they
          engage in certain paid or unpaid work with children, described as
          ‘child-related work’ under the WWC Act.{" "}
          <a
            href="https://www.wa.gov.au/organisation/department-of-communities/working-children-check"
            target="_blank"
            rel="noopener noreferrer"
          >
            Working with Children Checks
          </a>{" "}
          are for a defined period (generally three years). They are not
          transferable between States or Territories.
        </p>
        <p>
          The relevant criteria in the legislation is extensive and very
          specific. The State Government website provides information to assist.
          Having clear policies regarding the need to protect children (and
          other vulnerable groups), undertaking reference checking and
          interviews and provide adequate induction, training and supervision
          are also important.
        </p>
      </>
    ),
  },
  {
    title: "How to address risk management with youth volunteers?",
    content: (
      <>
        <p>
          Before engaging a youth volunteer, the organisation needs to consider
          the ability to provide suitable duties, supervision and meet the duty
          of care to provide a safe space for the young person.
        </p>
        <p>
          Having clear policies regarding the need to protect children (and
          other vulnerable groups), undertaking reference checking and
          interviews and provide adequate induction, training and supervision
          are also important.Volunteer policies and procedures should expressly
          consider risks and issues associated with youth volunteers under 18
          years old. There are{" "}
          <a
            href="https://childsafe.humanrights.gov.au/national-principles"
            target="_blank"
            rel="noopener noreferrer"
          >
            National Principles for Child Safe Organisations
          </a>
          , which are considered best practice for fostering child safety and
          wellbeing culture and practice.
        </p>
      </>
    ),
  },
  {
    title: "Does insurance cover youth volunteers?",
    content: (
      <p>
        Volunteering WA encourages all VIOs to review any conditions of their
        Volunteer Accident Policies with their insurance company, to ensure all
        volunteers are adequately covered. See more information on the VWA
        website.{" "}
        <a
          href="https://www.volunteeringwa.org.au/volunteer-management/tools/faqs-for-volunteer-managers"
          target="_blank"
          rel="noopener noreferrer"
        >
          FAQs for Volunteer Managers
        </a>
      </p>
    ),
  },
  {
    title: "How to attract youth volunteers?",
    content: (
      <>
        <p>
          Talk to any young volunteers within the organisation and tap into
          their networks. Promote the achievements and gains of the young people
          already in the organisation through social media. Encourage
          volunteering with friends, family or in small groups. Share the vision
          and passion for your organisation and the great work that is being
          done.
        </p>
        <p>
          Review existing volunteer marketing and social media to ensure it is
          being seen by young people, and that it is the kind of thing that will
          appeal to youth. Social media is constantly changing, so find out
          where youth are at. Emphasise the element of personal and professional
          development and mention specifically that you're looking for young
          people. Tap into local youth organisations including schools or
          universities.
        </p>
      </>
    ),
  },
  {
    title: "What can young people get out of volunteering?",
    content: (
      <p>
        Ideally, volunteering contributes to the personal and professional
        development of the volunteer. It can bring job and transferrable skills,
        communication skills, experience, contacts, and increased
        self-confidence. All these can help in the search for employment.
        Volunteering can provide good recognition for service and referees for
        job applications. Youth volunteers can also gain the satisfaction of
        knowing they are making a difference. And it can be fun. Young people
        will often enjoy the chance to meet like-minded people and make friends.
      </p>
    ),
  },
  {
    title: "What drives young people away?",
    content: (
      <p>
        Lengthy application processes that are not digitally compatible may be
        discouraging to youth. Youth volunteers can also be put off by the same
        things that discourage other volunteers - poor organisation, poor
        supervision or unsatisfying jobs. Young people may be sensitive to being
        'talked at' or 'talked down to', so aim to promote reciprocal
        relationships with youth. They will expect meaningful duties, not ad hoc
        tasks that nobody else wants to do. Listen respectfully to youth
        opinions and give their ideas due consideration, actively involving them
        in decision making where possible.
      </p>
    ),
  },
  {
    title:
      "What would our organisation need to change to include youth volunteers?",
    content: (
      <>
        <p>
          Organisations may need consider more flexible schedules, looking for
          tasks that can be done outside of standard work hours or that can be
          done virtually. Review systems for email and text (SMS) mobile phone
          communication with volunteers. More information on{" "}
          <a
            href="https://www.volunteeringwa.org.au/resources/fact-sheets-and-guides"
            target="_blank"
            rel="noopener noreferrer"
          >
            virtual volunteering
          </a>{" "}
          is available from VWA.
        </p>
        <p>
          Digital induction and training procedures might need to be reviewed so
          that they are simple and not overwhelming. Make it sound inclusive,
          fun, friendly and rewarding to get involved! Consider mentoring or
          coaching schemes that allocate more time at the beginning for
          supervision, and then for follow-up for tasks that start small and
          build up as confidence and skills develop. Allowing volunteers to make
          mistakes, and see this as a learning experience rather than a failure
          is important.
        </p>
        <p>
          Look over the volunteer program or projects and ask if there are any
          changes that could be made if there was a younger team involved. There
          may also be grants available for community groups that involve young
          people or projects, where young people can learn and gain employment
          skills.
        </p>
      </>
    ),
  },
];
