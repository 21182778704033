import { Meta, Layout, Section } from "~/ui";
import { Routes } from "~/models";

import opShop from "~/assets/images/categories/op-shop.jpg";
import DynamicAccordion from "~/components/Accordion";
import { faq } from "~/static-data/for-organisations-faq";
import VideoCards from "~/components/OrganisationsVideoCards";

export default function TipsForOganisations(): JSX.Element | null {
  return (
    <Layout>
      <Section
        bgColor="creamGradient"
        baseBgColorTop="primary"
        roundedCorners={{ "top-left": true }}
      >
        <div className="row mb-4">
          <div className="col-12 col-lg-8 mb-4">
            <h1 className="d-flex flex-column text-success mb-4">
              Tips for
              <span className="display-1">Organisations</span>
            </h1>
            <div className="pe-lg-6">
              <p className="mb-3">
                YVolunteer is the place to advertise your youth-friendly
                volunteer roles. But what makes a volunteer role youth friendly?
              </p>
              <p>
                Volunteering WA has collaborated with Youth Affairs Council of
                WA to put together some resources to help you navigate what
                motivates young people to volunteer and how you can attract more
                youth to your organisation.
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-4 text-center mt-lg-n5 mb-4">
            <img
              src={opShop}
              alt="Two youth volunteers collecting marine seeds as part of a beach volunteering day"
              className="object-fit-cover"
              width={295}
              height={400}
            />
          </div>
        </div>

        <VideoCards />

        <div className="row">
          <div className="col-12 text-center">
            <h2 className="position-relative d-inline-block p-4">
              <span
                className="position-relative font-weight-normal text-success"
                style={{ zIndex: 1 }}
              >
                How to Add a Volunteer Role
              </span>
              <span className="position-absolute-0 strokes stroke-1" />
            </h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <p className="fw-light mb-5">
              Easy steps to add a volunteer role on YVolunteer (Western
              Australia)
            </p>
            <ol className="circle-list">
              <li>
                <span className="">
                  <mark>Sign up</mark> for Volunteering{" "}
                  <a href="https://login.vol.org.au/">WA's VIKTOR Lite</a> (if
                  you are not already)
                </span>
              </li>
              <li>
                <div>
                  <p className="mb-2">
                    Select the “Youth Volunteer Roles” template and{" "}
                    <mark>complete the form</mark>. Alternatively, when logging
                    your role on VIKTOR or SEEK Volunteering, use either
                    category:
                  </p>
                  <ul>
                    <li>Suitable for Young Adults – 18-24</li>
                    <li>Younger Volunteers – under 18</li>
                  </ul>
                </div>
              </li>
              <li>
                <span>
                  <mark>Publish</mark> your role and it will be displayed on our
                  site about
                </span>
              </li>
            </ol>
          </div>
        </div>
      </Section>

      <Section
        id="faq"
        bgColor="offWhite"
        baseBgColorTop="creamGradientEnd"
        roundedCorners={{ "top-right": true }}
      >
        <div className="mb-4 mb-lg-5">
          <h3 className="d-flex flex-column text-dark">
            <span className="h1">FAQs for organisations</span>
            <span className="display-1">Working with Youth</span>
          </h3>
        </div>

        <p className="mb-4 mb-lg-5">
          <i>
            The advice in this FAQ document is of a general nature only. There
            may be specific considerations for each organisation and sector.
            Always seeks legal or professional advice if you are unsure.
          </i>
        </p>
        <DynamicAccordion items={faq} />
      </Section>
    </Layout>
  );
}

export const Head = () => {
  return <Meta title="Getting Ready" url={Routes.GettingReady} />;
};
